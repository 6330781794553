<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="reports" :search="search">
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.puente.nombre }}</td>
          <td>{{ row.item.nombre }}</td>
          <td>{{ row.item.fecha_publicacion }}</td>
          <td>
            <v-btn class="mx-2" fab dark small color="pink" download :href="row.item.archivo" target="_blank">
              <v-icon dark>mdi-download</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
  
<script>
import { FETCH_PDF_REPORTS } from '@/store/actions.type'
import { mapState } from 'vuex';
export default {
  name: 'Reports',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Puente',
          align: 'start',
          value: 'puente.nombre',
        },
        { text: 'Reporte', value: 'nombre' },
        { text: 'Fecha Publicación', value: 'fecha_publicacion' },
        { text: 'Descargar', value: 'url' },
      ],
    }
  },
  computed: {
    ...mapState({
      reports: state => state.reports
    })
  },
  created() {
    this.$store.dispatch(FETCH_PDF_REPORTS)
  }
}
</script>
  